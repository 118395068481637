/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalApp } from "./auth-provider";
import { Button } from "antd";
import axios from "axios";
import {
  MsalProvider,
  useMsal,
  useIsAuthenticated,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { LogOut } from "@styled-icons/ionicons-outline/LogOut";
import { loginRequest } from "./config";
import "./index.css";
import { Result, Select, Popover, Space, Avatar, Skeleton } from "antd";
// import * as AuthService from "@pai/services/auth";
import { InteractionStatus } from "@azure/msal-browser";
import { LoadingOutlined } from "@ant-design/icons";
import { Card, CardMeta, Row, Col } from "@pai-ui/core";
// import axiosInstance from "../core/index-core-maverick";
import { WaveLoading } from "styled-spinkit";
import { LockFill } from "@styled-icons/bootstrap/LockFill";
import { FortAwesome } from "@styled-icons/fa-brands/FortAwesome";
import { NoEntry } from "@styled-icons/boxicons-solid/NoEntry";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    authority: process.env.REACT_APP_AUTH_TENANT, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_AUTH_RETURN_URL,
    postLogoutRedirectUri: `${process.env.REACT_APP_AUTH_RETURN_URL}logout`,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const defaultUser = {
  username: "souvik.saha@zs.com",
  name: "Souvik Saha",
};

const setUserInfoInHeaders = (account) => {
  // Method to set user info and pass through api headers.
  axios.interceptors.request.use(
    (config) => {
      console.log("config", config);
      if (config?.skipInterceptors) {
        return config;
      } else {
        // if (process.env.REACT_APP_PANERA_ZS_DEV) {
        // config.headers["userid"] = account.username; // username from AD is accepted as userid in node solution
        // config.headers["username"] = account.name; // name from AD is accepted as username in node solution
        //   config.headers.common["kubeflow-userid"] = "souvik.saha@zs.com";
        //   config.headers.common["x-request-id"] =
        //     "9c332de3-5bde-9778-871f-728719b9594c";
        // }
        return config;
      }
    },
    (error) => {
      console.log(error);
    }
  );
};

export const UserContext = React.createContext();

const initialState = {
  user: undefined,
  identity: undefined,
  loading: true,
  error: undefined,
  token: undefined,
  role: undefined,
  format: undefined,
  status: undefined,
};

const getPermissions = (role) => {
  let permissions = {};
  role.permissions.forEach((p) => {
    let permssionType = permissions[p.type];
    permissions[p.type] = permssionType || {};
    permissions[p.type][p.path] = permissions[p.type][p.path] || {};
    permissions[p.type][p.path][p.permission] = true;
  });
  return permissions;
};

const selectRole = (user, roleId) => {
  const allRoles = user.accesses;
  const role = allRoles.find((r) => r.role.id === roleId);
  return {
    role: role.role,
    permissions: getPermissions(role),
  };
};

const userReducer = (state, action) => {
  // console.log("reducer called", state, action);
  switch (action.type) {
    case "identity":
      return {
        ...state,
        identity: action.payload,
      };
    case "role":
      return {
        ...state,
        role: selectRole(state.user, action.payload),
      };
    case "user":
      // window.token = action.payload.token;
      return {
        ...state,
        user: action.payload.user,
        format: action.payload.format,
        status: action.payload.status,
        loading: false,
        error: undefined,
        token: action.payload.token,
        role: selectRole(
          action.payload.user,
          action.payload.user.accesses[0].role.id
        ),
      };
    case "error":
      return {
        ...state,
        error: action.payload,
      };
    case "logout":
      msalApp.logout();
      return {
        ...state,
        user: undefined,
      };
    case "format":
      return {
        ...state,
        format: action.payload,
      };
  }
};

export function handleLogout(instance) {
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
}
export const SignOutButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      type="link"
      icon={<LogOut height={20} width={20} />}
      onClick={() => handleLogout(instance)}
    >
      Logout
    </Button>
  );
};

function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}
function handleDefaultLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

const ComponentForAuthentication = ({ children }) => {
  //   const [graphData, setGraphData] = useState(null);

  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
  const [consent, setConsent] = useState(false);
  const [agents, setAgents] = useState([]);
  const [customLoading, setCustomLoading] = useState(true);
  const [state, dispatch] = React.useReducer(userReducer, initialState);

  let userData = {
    name: accounts?.[0]?.name,
    username: accounts?.[0]?.username,
  };

  setUserInfoInHeaders(userData);

  const requestProfileData = React.useCallback(
    function requestProfileData() {
      const request = {
        ...loginRequest,
        //scopes:[""]
        account: accounts[0],
      };
      // console.log("accessToken");

      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance
        .acquireTokenSilent(request)
        .then(async (response) => {
          console.log("accessToken", response);
          //FIXME
          let metaData = { token: response?.accessToken, user: userData };
          window.localStorage.setItem("currUrl", instance?.config.auth.state);
          // const user = {
          //   email: response?.account?.username,
          //   name: response?.account?.name,
          // };
          // const consentResponse = await axiosInstance.post(
          //   "/users/status",
          //   user
          // );
          const consentResponse = {
            data: {
              consentStatus: true,
              agents: []
            }
          }
          console.log({ consentResponse });
          setConsent(consentResponse.data?.consentStatus);
          setAgents(consentResponse.data?.agents);
          setCustomLoading(false);
          //#region - getuserId,username
          //   / /Not needed as getAPILogin is not in use
          //   const activeAccount = instance.getActiveAccount();
          //   let additionalHeaders = {
          //     userid: activeAccount.userName,
          //     username: activeAccount.name,
          //   };
          //#endregion - getuserId,username
          // let apiData = await AuthService.getMetaData(
          //   response?.accessToken
          //   // additionalHeaders
          // );
          // metaData = { ...metaData, ...apiData };

          // console.log("metadata", metaData, apiData);
          // dispatch({
          //   type: "metaData",
          //   payload: metaData,
          // });
          // setIsLoginSuccessful(isAuthenticated);
        })
        .catch((e) => {
          instance.acquireTokenPopup(request).then((response) => {
            setIsLoginSuccessful(false);
          });
        });
    },
    [isAuthenticated]
  );

  useEffect(() => {
    if (isAuthenticated) requestProfileData();
  }, [isAuthenticated]);
  const activeAccount = instance.getActiveAccount();

  if (
    inProgress === InteractionStatus.None &&
    !isAuthenticated &&
    window.location.pathname.indexOf("logout") == -1
  ) {
    setTimeout(() => {
      if (accounts.length === 0) {
        // handleDefaultLogin(instance);
        handleLogin(instance);
      }
    }, 100);
  }

  const Component = () => {
    console.log("loading component", InteractionStatus, isAuthenticated);
    var Item = null;
    if (isAuthenticated) Item = <div>{children}</div>;
    else if (!isAuthenticated) {
      Item = (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Card
              size="large"
              style={{ minWidth: 300, maxWidth: 300, textAlign: "center" }}
              bordered={false}
              bodyStyle={{ backgroundColor: "transparent" }}
              // loading={true}
              actions={[
                // eslint-disable-next-line react/no-unknown-property
                <div key={1} align="center" style={{ color: "#15afd0" }}>
                  {inProgress === InteractionStatus.None
                    ? "Redirecting for login"
                    : "Fetching Details"}
                </div>,
              ]}
            >
              <div>
                <Skeleton.Avatar active size="large" />
              </div>
              <div style={{ marginTop: "20px" }}>
                {" "}
                <Skeleton
                  active
                  shape="round"
                  paragraph={{ rows: 3, width: 250 }}
                />
              </div>
            </Card>
          </div>
        </div>
      );
    } else {
      Item = (
        <div className="login-component-wrapper">
          <Result
            status="403"
            title="Max.AI-Nova"
            subTitle="Authentication Failed. Please Retry"
            extra={
              <Button type="primary" onClick={() => handleLogin(instance)}>
                Login
              </Button>
            }
          />
        </div>
      );
    }

    return Item;
  };

  console.log("auth useEffect", {
    isAuthenticated,
    activeAccount,
    instance,
    state,
  });

  return (
    <UserContext.Provider value={{ ...state, agents, dispatch }}>
      {/* <Component /> */}
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      {/* <AuthenticatedTemplate>
        {customLoading ? (
          <WaveLoading color="#14AFD0" />
        ) : (
          (consent && children) || (
            <div
              style={{
                margin: "20px",
                marginRight: "0px",
                paddingRight: "20px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  fontSize: "18px",
                  color: "#14afd0",
                  borderBottom: "1px solid #323232",
                  fontWeight: 500,
                  paddingBottom: "8px",
                  marginBottom: "15px",
                }}
              >
                Terms of Service for Nova
              </div>
              <div
                style={{
                  height: "91vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                  padding: "10px 60px",
                }}
              >
                <Row align="middle">
                  <Col span={24}>
                    <div
                      style={{
                        width: "100%",
                        fontSize: "16px",
                        textAlign: "justify",
                        fontWeight: 500,
                      }}
                    >
                      Welcome to Nova! Before you dive into the awesomeness that
                      we've built, there are some ground rules we need to cover.
                      This isn't just fine print—it's the playbook for keeping
                      things smooth and professional while you're with us. Let's
                      keep things fun and professional!
                    </div>
                  </Col>
                </Row>
                <div
                  style={{
                    width: "100%",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                    paddingTop: "30px",
                    borderBottom: "1px solid #323232",
                    paddingBottom: "8px",
                    color: "#14afd0",
                  }}
                >
                  The Three Commandments
                </div>

                <Row className="card-container-row" gutter={[20, 20]}>
                  <Col span={8}>
                    <Card>
                      <div
                        style={{
                          paddingBottom: "10px",
                          fontSize: 18,
                          textAlign: "center",
                        }}
                      >
                        Be Smart
                      </div>
                      <LockFill color="#15afd0" height={100} />
                      <div style={{ fontSize: 16, paddingTop: "10px" }}>
                        Do not use or share or upload personal, sensitive, or
                        client-related details. Be wise.
                      </div>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card>
                      <div
                        style={{
                          paddingBottom: "10px",
                          fontSize: 18,
                          textAlign: "center",
                        }}
                      >
                        Your Data, Your Agent
                      </div>
                      <FortAwesome color="#15afd0" height={100} />
                      <div style={{ fontSize: 16, paddingTop: "10px" }}>
                        Please note that the agents are public and can see what
                        you have uploaded and created. Agents and data is tied
                        to your ZS ID.
                      </div>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card>
                      <div
                        style={{
                          paddingBottom: "10px",
                          fontSize: 18,
                          textAlign: "center",
                        }}
                      >
                        Say No to NSFW
                      </div>
                      <NoEntry color="#15afd0" height={100} />
                      <div style={{ fontSize: 16, paddingTop: "10px" }}>
                        Keep it classy, folks! Our app is a clean, well-lit
                        place for professional behavior. NSFW or vulgar content
                        will be shown the exit.
                      </div>
                    </Card>
                  </Col>
                </Row>
                <div
                  style={{
                    width: "100%",
                    fontSize: "16px",
                    color: "#14afd0",
                    fontWeight: 500,
                    marginBottom: "2px",
                    paddingTop: "30px",
                    borderBottom: "1px solid #323232",
                    paddingBottom: "8px",
                    marginBottom: "15px",
                  }}
                >
                  The Detailed Terms (The Fine Print)
                </div>
                <div
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    textAlign: "justify",
                  }}
                >
                  For those who appreciate the specifics, here's the more
                  detailed breakdown:
                  <ul>
                    <li
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      Responsibility: You're solely responsible for any content
                      you upload. Think before you hit that "submit" button.
                    </li>
                    <li
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      Ownership: What's yours is yours. By uploading, you are
                      giving us a license to use that content within Nova.
                    </li>
                    <li
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      Permissible Content: Keep it clean, keep it legal, and
                      keep it friendly.
                    </li>
                    <li
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      No Sensitive Data: Seriously, we can't stress this enough.
                      If it could be used to steal someone's identity, access
                      bank accounts, or share sensitive client information
                      including client names and project details, don't upload
                      it. Be smart about what you share.
                    </li>
                    <li
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      Acceptable Use
                      <ul>
                        <li
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            marginTop: "10px",
                          }}
                        >
                          Keep It Clean: NSFW and vulgar content are out.
                          Professional and polite are in.
                        </li>
                        <li
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            marginTop: "10px",
                          }}
                        >
                          Respect the Law: Illegal? Unethical? Not here, not
                          ever.
                        </li>
                        <li
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            marginTop: "10px",
                          }}
                        >
                          Community Respect: We're a mosaic of individuals.
                          Celebrate and respect the diversity.
                        </li>
                      </ul>
                    </li>
                    <li
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      Updates: Terms will evolve, just like our app. We'll let
                      you know when we change them, but it's on you to stay
                      informed.
                    </li>
                    <li
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      Breaking the Rules: If you break these rules, your agents
                      might stop working. No exceptions.
                    </li>
                  </ul>
                </div>
                <div
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    textAlign: "justify",
                    marginTop: "30px",
                  }}
                >
                  By using Nova, you agree to these terms. If they don't suit
                  you, then our digital paths may need to diverge.
                </div>
                <div
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    textAlign: "justify",
                    marginTop: "10px",
                  }}
                >
                  Have fun, be cool, and enjoy Nova!
                </div>
                <div
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    textAlign: "justify",
                    marginTop: "10px",
                  }}
                >
                  Last updated: 11/21/2023
                </div>
                <div
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    textAlign: "justify",
                    marginTop: "10px",
                  }}
                >
                  <i>
                    These terms and conditions were written for Nova by Nova
                    (GPT4).
                  </i>
                </div>
              </div>
              <div style={{ paddingTop: "15px", textAlign: "end" }}>
                <Button onClick={() => consentHandler()} type="primary">
                  Accept Terms of Service
                </Button>
              </div>
            </div>
          )
        )}
      </AuthenticatedTemplate> */}
      <UnauthenticatedTemplate>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            {window.location.pathname.indexOf("logout") > -1 ? (
              <Card
                size="large"
                bodyStyle={{ backgroundColor: "transparent" }}
                bordered={false}
              >
                <Result
                  status="success"
                  title="You have successfully Logged out from Max.AI-KITT"
                  subTitle="Please click below button to Login !"
                  extra={
                    <Button
                      type="primary"
                      onClick={() => handleLogin(instance)}
                    >
                      Login
                    </Button>
                  }
                />
              </Card>
            ) : (
              <Card
                size="large"
                style={{ minWidth: 300, maxWidth: 300, textAlign: "center" }}
                bordered={false}
                bodyStyle={{ backgroundColor: "transparent" }}
                // loading={true}
                actions={[
                  // eslint-disable-next-line react/no-unknown-property
                  <div key={1} align="center" style={{ color: "#15afd0" }}>
                    {inProgress === InteractionStatus.None
                      ? "Redirecting for Login"
                      : "Fetching Details"}
                  </div>,
                ]}
              >
                <div>
                  <Skeleton.Avatar active size="large" />
                </div>
                <div style={{ marginTop: "20px" }}>
                  {" "}
                  <Skeleton
                    active
                    shape="round"
                    paragraph={{ rows: 3, width: 250 }}
                  />
                </div>
              </Card>
            )}
          </div>
        </div>
      </UnauthenticatedTemplate>
    </UserContext.Provider>
  );
};

const ComponentWithoutAuthentication = ({ children }) => {
  const { /* instance, */ accounts /* , inProgress */ } = useMsal();
  const [state, dispatch] = React.useReducer(userReducer, initialState);

  // useEffect(async () => {
  //   let tempToken = "need-some-token-to-work-with-adding-dummy-text-for-now";
  //   let userData = {
  //     name: accounts?.[0]?.name || defaultUser?.name,
  //     username: accounts?.[0]?.username || defaultUser?.username,
  //   };
  //   setUserInfoInHeaders(userData);
  //   let metaData = { token: tempToken, user: userData };
  //   console.log("accessToken", metaData, accounts);
  //   let apiData = await AuthService.getMetaData(tempToken);
  //   metaData = { ...metaData, ...apiData };

  //   console.log("metadata", metaData, apiData);
  //   dispatch({
  //     type: "metaData",
  //     payload: metaData,
  //   });
  // }, []);

  return (
    <UserContext.Provider value={{ ...state, dispatch }}>
      <>{children}</>
    </UserContext.Provider>
  );
};

export const withAuth = (Component) =>
  function Auth(props) {
    let newConfig = JSON.parse(JSON.stringify(msalConfig));
    newConfig.auth.state = window.location.pathname;
    const msalInstance = new PublicClientApplication(newConfig);
    // const msalInstance = new PublicClientApplication(msalConfig);
    console.log(
      "msalInstance",
      msalInstance.getAllAccounts(),
      msalInstance.getActiveAccount()
    );

    // Default to using the first account if no account is active on page load
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    msalInstance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
      ) {
        msalInstance.setActiveAccount(event.payload.account);
      }
    });

    console.log(
      "msalInstance 2",
      { msalInstance },
      process.env.REACT_APP_IS_AD_REQUIRED
    );
    return (
      <MsalProvider instance={msalInstance}>
        {process.env.REACT_APP_IS_AD_REQUIRED?.toLowerCase() === "true" ? (
          <ComponentForAuthentication>
            <Component {...props} />
          </ComponentForAuthentication>
        ) : (
          <ComponentWithoutAuthentication>
            <Component {...props} />
          </ComponentWithoutAuthentication>
        )}
      </MsalProvider>
    );
  };
